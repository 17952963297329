.workflow-wizard {
  & .wizard-btn-container {
    display: none;
  }

  & .line {
    inline-size: calc(100% - 1.5rem) !important;
    inset-inline-start: calc(50% + 0.75rem) !important;
  }

  & .step.small {
    inline-size: 17% !important;
  }

  & .step {
    padding: 0 !important;
  }

  & .container-steps:not(.vertical) {
    justify-content: flex-start !important;
  }

  & .container-steps {
    padding-inline: 0 !important;
    margin-left: -10% !important;
  }

  & .title {
    font-weight: 400 !important;
    font-size: 1rem !important;
    margin-block-start: 0 !important;
    position: relative;
    inline-size: max-content;
    left: 17px;
    top: 1px;
    width: fit-content !important;
  }
}

.workflow-wizard-user {

  & .line {
    // inline-size: 100% !important;
  }

  & .container-steps {
    inline-size: 88%;
    position: relative;
    left: -1rem;
  }
}

.workflow-tooltip {
  font-size: 12px;
  padding: 10px 15px;
  background-color: #f7fff7;
  width: 18vw;
  z-index: 100;
  color: black;
  position: absolute;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
}

.workflow-tooltip-top::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 0;
  border: 8px solid transparent;
  border-bottom: 0;
  border-top: 8px solid white;
  transform: translate(-50%, calc(100%));
}

.workflow-tooltip-right::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 0;
  top: 50%;
  border: 8px solid transparent;
  border-left: 0;
  border-right: 8px solid white;
  transform: translate(calc(-100%), -50%);
}

.workflow-tooltip-row {
  display: flex;
  padding: 5px;
}

.workflow-tooltip-label {
  flex: 1 1 30%;
  display: flex;
  font-weight: bold;

  & div {
    margin-left: auto;
  }
}

.workflow-tooltip-value {
  flex: 0 0 70%;
  display: flex;
  justify-content: flex-start;
  padding-left: 8px;
  text-align: start;
}

.workflow-tooltip-status {
  display: flex;
  height: 24px;
  width: fit-content;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  color: white;
}

.stage-details {

  font-size: 1rem;

  & div {
    margin-bottom: .4rem;
  }

  &__name {
    font-weight: 600;
  }

  &__action-by {

  }

  &__craeted-on {

  }
}

